import React from 'react';
import SEO from '../components/seo';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../components/layout';

const DatenschutzPage = () => {

  const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Homepage"}) {
                id
                title
                description
                contentful_id
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                },
                contentModule {
                    ... on Node {
                        id
                        __typename
                    }
                }
            }
        }
    `);

  const menus = data.contentfulLayout.menu;
  const contentModule = data.contentfulLayout.contentModule;

  const googleOptOut = () => {
    //document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    alert('Google Analytics wurde deaktiviert')
  }

  return (
    <Layout menus={ menus }>
      <SEO title="Datenschutz" />

      <div className="container mx-auto px-5" id="datenschutz">
        <div className="text-4xl my-16">MyFlexHome Datenschutz</div>

        <h1>Datenschutzerklärung</h1>
        <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <p className="my-4">datenschutz@myflexhome.de</p>
        <p>Das Wichtigste zuerst: Der Schutz Ihrer Privatsphäre liegt uns am Herzen. Jegliche Datenverarbeitung erfolgt nach geltenden gesetzlichen Vorschriften. Uns ist es ein besonderes Anliegen, all Ihre personenbezogenen Daten zu schützen und sicher zu verwahren. In diesem Dokument erfahren Sie mehr darüber, wie wir Ihre personenbezogenen Daten verwenden und verarbeiten.</p>

        <h2>Ihre Betroffenenrechte</h2>
        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
        <ul>
          <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
          <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
          <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
        </ul>
        <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>

        <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
        <ul>
          <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zur Optimierung unserer Website.</li>
        </ul>
        <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>
        <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
        <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
        <h3>Speicherdauer:</h3>
        <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist. </p>
        <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine  Zuordnung  des aufrufenden Clients nicht mehr möglich ist.</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>

        <h2>Registrierung auf unserer Website</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Für die Registrierung auf unserer Website benötigen wir einige personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden. </p>
        <p>Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten erhoben:</p>
        <p>Vorname, Nachname, E-Mail-Adresse, Adresse</p>
        <p>Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
        <h3>Speicherdauer:</h3>
        <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte gewähren. </p>

        <h2>Erbringung kostenpflichtiger Leistungen</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu können.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <h3>Speicherdauer:</h3>
        <p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und steuerrechtlichen Anforderungen.</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gewähren.</p>


        <h2>Newsletter</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse. </p>
        <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf formlos per E-Mail an datenschutz@myflexhome.de mitteilen.</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <h3>Speicherdauer:</h3>
        <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht zusenden.</p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person beantragt werden. </p>


        <h2>Kontaktformular</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
        <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
        <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <h3>Speicherdauer:</h3>
        <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
        <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>

        <h2>Teilnahme an Umfragen</h2>
        <p>Für die technische Durchführung von Umfragen auf unserer Internetseite nutzen wir das Angebot von SurveyMonkey Europe UC, 2 Shelbourne Buildings, Shelbourne Road, Dublin, Ireland.</p>
        <p>Die Teilnahme an der Umfrage ist freiwillig, ebenso die Beantwortung einzelner Fragen. Anhand der Umfrage-Ergebnisse erstellen wir anonyme Auswertungen, die keinen Bezug zu Ihrer Person haben. Wir werden Ihre Antworten nicht zusammen mit den Sie identifizierenden Daten (z.B. Name, E-Mail) speichern.</p>
        <p>Wir werden die E-Mail-Adressen der Umfrage-Teilnehmer nur zu den von ihnen freigegebenen Zwecke nutzen, die im Rahmen der Umfrage in einer separaten Fragen freizugeben sind. Dabei kann die Freigabe zur Nutzung auf (1) die Teilnahme am Gewinnspiel im Rahmen der Umfrage und/oder (2) den Erhalt der anonymisierten und aufbereiteten Umfrageergebnissen im Nachgang der Studie und/oder (3) die Bestellung unserer Newsletters (siehe auch separates Kapitel der Datenschutzrichtlinie "Newsletter") erfolgen.</p>
        <p>Wir verwenden SurveyMonkey um Ihnen Umfragen zur Verfügung zu stellen. In diesem Zweck liegt auch unser berechtigtes Interesse an der Verarbeitung der genannten Daten, diese beruht auf Art. 6 Abs. 1 S. 1 lit. b DSGVO</p>
        <p>SurveyMonkey erhebt statistische Daten über den Besuch dieser Website. Zu den Zugriffsdaten gehören: Datum und Uhrzeit des Abrufs, Browsertyp und Version, das Betriebssystem des Nutzers, Referer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. SurveyMonkey verwendet die Protokolldaten für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebots.</p>
        <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von SurveyMonkey: <a href="https://www.surveymonkey.de/mp/legal/privacy/" rel="noopener nofollow" target="_blank">https://www.surveymonkey.de/mp/legal/privacy/</a>.</p>

        <h2>Verwendung von Google Analytics</h2>
        <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. </p>
        <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://policies.google.com/?hl=de" rel="noopener" target="_blank">https://www.google.com/analytics/terms/de.html</a> und unter <a href="https://policies.google.com/?hl=de" rel="noopener" target="_blank">https://policies.google.com/?hl=de</a>. </p>
        <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. </p>
        <p>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie <a title="Google Analytics Opt-Out-Cookie setzen" onClick={googleOptOut} href="#">diesen Link anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, solange das Cookie in Ihrem Browser installiert bleibt.</p>
        <p>Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. </p>
        <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener" target="_blank">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>

        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
        <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" rel="noopener nofollow" target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" rel="noopener nofollow" target="_blank">https://www.google.com/policies/privacy/</a>.</p>

        <h2>Verwendung von Google Maps</h2>
        <p>Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
          <br />Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen: <a href="https://policies.google.com/privacy" rel="noopener nofollow" target="_blank">https://policies.google.com/privacy</a>. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
        <p>Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie hier: <a href="https://www.dataliberation.org/" rel="noopener nofollow" target="_blank">https://www.dataliberation.org</a></p>
        <p>Durch den Besuch der Website erhält Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. </p>
        <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Websites. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. </p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>

        <h2>Verwendung von Mapbox</h2>
        <p>Auf dieser Website nutzen wir das Angebot des Kartendienst Mapbox. Anbieter ist die MapBox Inc., 740 15th St NW, Washington, DC 20005, USA.</p>
        <p>Zur Nutzung der Funktionen von Mapbox ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Mapbox in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.</p>
        <p>Die Nutzung von Mapbox Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
        <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Mapbox: <a href="https://www.mapbox.com/privacy/" rel="noopener nofollow" target="_blank">https://www.mapbox.com/privacy/</a>.</p>

        <h2>Verwendung von CloudFlare</h2>
        <p>Auf dieser Website nutzen wir das Angebot des Content Delivery Network mit DNS des Anbieters CloudFlare, Inc. 665 3rd St. #200, San Francisco, CA 94107, USA.</p>
        <p>Technisch wird der Informationstransfer zwischen Ihrem Browser und unserer Website über das Netzwerk von CloudFlare geleitet. CloudFlare ist damit in der Lage, den Datenverkehr zwischen Anwender und unseren Webseiten zu analysieren, um beispielsweise Angriffe auf unsere Dienste zu erkennen und abzuwehren. Zudem speichert CloudFlare zur Optimierung und Analyse unter Umständen Cookies auf Ihrem Rechner. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimalen Vermarktung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO.</p>
        <p>Cloudflare erhebt statistische Daten über den Besuch dieser Website. Zu den Zugriffsdaten gehören: Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp und Version, das Betriebssystem des Nutzers, Referer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. Cloudflare verwendet die Protokolldaten für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebots.</p>
        <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von CloudFlare: <a href="https://www.cloudflare.com/security-policy" rel="noopener nofollow" target="_blank">https://www.cloudflare.com/security-policy</a>.</p>

        <h2>SSL-Verschlüsselung</h2>
        <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>

        <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
        <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
        <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
        <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        <h3>Empfänger eines Widerspruchs</h3>
        <p>datenschutz@myflexhome.de</p>

        <h2>Änderung unserer Datenschutzbestimmungen</h2>
        <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>

        <h2>Fragen zum Datenschutz</h2>
        <p>Wenn Sie Fragen zu den vorliegenden Bestimmungen zu Cookies haben, schicken Sie bitte eine E-Mail an  datenschutz@myflexhome.de</p>
      </div>
    </Layout>
  )};

export default DatenschutzPage;
